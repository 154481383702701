import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image";
import { Helmet } from "react-helmet"

import Layout from "../components/layout"
import Rule from '../components/rule';

const MediaPage = (props) => {
  const { data: { file: { childImageSharp: { fluid }}}} = props;
  return (
    <Layout>
      <Helmet>
        <title>Meedia | SA Mälestusselts „Artur Sirk“</title>
        <meta property="og:title" content="Meedia | SA Mälestusselts „Artur Sirk“" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={`https://www.artursirk.ee/${fluid.src}`} />
      </Helmet>
      <Rule />
        <Img
            itemProp="image"
            fluid={fluid}
            alt=""
        />
      <p className="hide-on-print">
        <Link to="/artur-sirk#media">Tagasi Artur Sirgu eluloo juurde</Link>
      </p>
      <Rule last />
    </Layout>
  );
};

export const pageQuery = graphql`
  query($relativePath: String!) {
    file(relativePath: { eq: $relativePath }) {
        childImageSharp {
            fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
            }
        }
    }
  }
`;

export default MediaPage;